.App {
  text-align: center;
  height: 100vh;
}

.Navbar {
  justify-content: space-between;
  background-color: #69bc46;
  color: white;
}

.ErrorMessage {
  margin-top: 30%;
}

/* Tooltip text */
.tooltiptext {
  visibility: hidden;
  width: 20vw;
  background-color: rgb(255, 255, 255);
  color: rgb(48, 47, 47);
  text-align: center;
  padding: 5px 0;
  border-radius: 10px;
  border: 1px solid rgb(103, 155, 105) !important;
  position: absolute;
  z-index: 10;
}
